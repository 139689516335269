import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer';
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';

const Nota = (props) => {

    return(
        <Document>
            <Page size="LETTER" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>{props.documento.empresa.razonSocial}</Text>
                    <Text style={styles.subTitle}>DOCUMENTO PROVEEDOR</Text>
                </View>
                <View style={styles.proveedorContainer}>
                    <Text>N.I.T.: { props.documento.proveedor.nit }</Text>
                    <Text>PROVEEDOR: { props.documento.proveedor.razonSocial }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Centro de operacion: </Text>
                            <Text>{ props.documento.encabezado.co } - { props.documento.encabezado.coDescripcion }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento: </Text>
                            <Text>{ props.documento.encabezado.co + "-" + props.documento.encabezado.tipoDoc + "-" + props.documento.encabezado.numDoc }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento proveedor: </Text>
                            <Text>{ props.documento.encabezado.numDocProv }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha: </Text>
                            <Text>{ formatDateMin( new Date( props.documento.encabezado.fechaDoc )) }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Detalle: </Text>
                            <Text>{ props.documento.encabezado.detalle }</Text>
                        </View>
                    </View>
                    <View style={styles.detalleContainer}>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>ITEM</Text>
                                <Text style={styles.tablaCampoTitulo}>DESCRIPCION</Text>
                                <Text style={styles.tablaCampoTitulo}>UNIDAD DE MEDIDA</Text>
                                <Text style={styles.tablaCampoTitulo}>CANTIDAD</Text>
                                <Text style={styles.tablaCampoTitulo}>VALOR BRUTO</Text>
                                <Text style={styles.tablaCampoTitulo}>DESCUENTOS</Text>
                                <Text style={styles.tablaCampoTitulo}>IMPUESTOS</Text>
                                <Text style={styles.tablaCampoTitulo}>TOTAL</Text>
                            </View>
                            {
                                props.documento.detalles.map(
                                    detalle => {
                                        console.log(detalle);
                                        return(
                                            <View key={detalle.id} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ detalle.idItem }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.descripcionItem }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.unidadMedida }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.cantidad }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.totalBruto) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.descuentos) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.impuestos) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.totalNeto) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={styles.tablaCampoTotal}>TOTAL:</Text>
                                <Text style={styles.tablaCampoTotal}></Text>
                                <Text style={styles.tablaCampoTotal}></Text>
                                <Text style={styles.tablaCampo}>{ props.documento.encabezado.cantidad }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(props.documento.encabezado.totalBruto) }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(props.documento.encabezado.descuentos) }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(props.documento.encabezado.impuestos) }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(props.documento.encabezado.totalNeto) }</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const NotaPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.documento){
            props.history.push('/portal');
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Nota documento={props.history.location.state.documento} />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 20,
        marginBottom: 10    
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 20
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        width: 64,
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: 64,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 64,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default NotaPDF;